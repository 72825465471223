html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Be Vietnam Pro', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #7085b1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main {
  margin-left: 300px;
}

main #topBtn {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #159bdb;
  cursor: pointer;
  border-radius: 50%;
}

main #topBtn img {
  margin: auto;
  width: 70%;
  -webkit-filter: invert(1);
          filter: invert(1);
}

main #topBtn:hover {
  background-color: #d7e3f7;
}

main #topBtn:hover img {
  -webkit-filter: invert(52%) sepia(46%) saturate(4655%) hue-rotate(170deg) brightness(97%) contrast(84%);
          filter: invert(52%) sepia(46%) saturate(4655%) hue-rotate(170deg) brightness(97%) contrast(84%);
}

section {
  width: 100%;
  padding: 50px 25px;
  background-color: #f5f8fd;
}

h2.sectionTitle {
  font-size: 26px;
  text-align: left;
  margin-bottom: 30px;
  text-decoration: underline;
  -webkit-text-decoration-color: #159bdb;
          text-decoration-color: #159bdb;
}

.sectionContainer {
  min-width: 900px;
  max-width: calc(1920px - 600px);
  margin: 0 auto;
}

.webNav {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  padding: 25px;
  background-color: black;
  color: white;
}

.webNav .navPicContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 5px auto 10px;
  border-radius: 50%;
  border: 8px solid #2c2f3f;
  background-color: #d1d2cd;
}

.webNav .navPicContainer #navPic {
  width: 130px;
  margin-top: -5px;
  padding-left: 3px;
}

.webNav .contactFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  margin: 5px 0;
}

.webNav .contactFlex .navSvgContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #2c2f3f;
}

.webNav .contactFlex #linkedin, .webNav .contactFlex #email, .webNav .contactFlex #github {
  width: 80%;
  border-radius: 50%;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.webNav .contactFlex #github {
  width: 60%;
  padding-bottom: 2px;
}

.webNav .contactFlex #email {
  width: 85%;
  padding: 0 0 1px 0;
}

.webNav .navSvgContainer:hover {
  background-color: #1483dd;
}

.webNav .navLinkFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 50px 25px;
}

.webNav h3, .webNav .largeFont {
  padding: 10px 0;
}

.webNav .navLink {
  color: #a8a9b4;
}

.webNav .navLink img {
  -webkit-filter: invert(71%) sepia(6%) saturate(367%) hue-rotate(197deg) brightness(95%) contrast(91%);
          filter: invert(71%) sepia(6%) saturate(367%) hue-rotate(197deg) brightness(95%) contrast(91%);
}

.webNav .navLink:hover {
  color: white;
}

.webNav .navLinkAtScroll {
  color: white;
}

.webNav .navLinkAtScroll img {
  -webkit-filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
          filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
}

.navLink {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25px auto;
      grid-template-columns: 25px auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  padding: 10px 0;
}

.navLink img {
  height: 18px;
}

.navLink span {
  -ms-grid-column-align: start;
      justify-self: start;
}

.navLink:hover {
  cursor: pointer;
}

.navLink:hover img {
  -webkit-filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
          filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
}

.navbar {
  display: none;
}

#offcanvasNavbar .navLink, #offcanvasNavbar .navLink:hover {
  color: black;
}

.aboutContainer {
  text-align: left;
  padding-top: 50px;
}

.aboutText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#profilePic {
  width: calc(1080px/3);
  border-radius: 50%;
  margin: 20px 0 0;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.skillsSection h2 {
  margin: 0;
}

.skillsSection .resumeTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  margin: 8px 0 30px;
}

.skillsSection .resumeDownload {
  font-size: 14px;
  min-width: 23px;
}

.skillsGrid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: (auto)[5];
      grid-template-rows: repeat(5, auto);
  -webkit-column-gap: 4em;
          column-gap: 4em;
  border-radius: 5px;
  margin: 0 0 50px;
  padding: 20px 40px 10px;
  background-color: white;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.skillsGrid .expGridTitle {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}

.skillsGrid .devSkillsLineTitle-1, .skillsGrid .devSkillsLineTitle-2 {
  padding-top: 20px;
  margin-bottom: 10px;
}

.skillsGrid .devSkillsLineTitle-1 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
}

.skillsGrid .devSkillsLineTitle-2 {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
}

.skillsGrid .leftContainer, .skillsGrid .rightContainer {
  width: 90%;
  -ms-grid-column-align: center;
      justify-self: center;
  margin-bottom: 20px;
}

.skillsGrid .leftContainer p, .skillsGrid .rightContainer p {
  margin-top: 10px;
}

.devSkillsLine-1, .devSkillsLine-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.devSkillsLine-1 img, .devSkillsLine-2 img {
  padding-top: 10px;
}

.devSkillsLine-1 {
  -ms-grid-columns: (auto)[4];
      grid-template-columns: repeat(4, auto);
  -ms-grid-rows: (auto)[2];
      grid-template-rows: repeat(2, auto);
  padding-bottom: 10px;
}

.devSkillsLine-1 img {
  height: 90px;
  margin-bottom: 0px;
}

.devSkillsLine-2 {
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  -ms-grid-rows: (auto)[4];
      grid-template-rows: repeat(4, auto);
}

.devSkillsLine-2 img {
  max-width: 50px;
  max-height: 45px;
}

.bottomContainer {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #e7effd;
  border-radius: 10px;
  margin-top: 10px;
}

.otherSkillsFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 250px;
  padding: 20px 0;
  row-gap: 15px;
}

.otherSkillTile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: #dddddd 1px solid;
  background-color: white;
  border-radius: 5px;
  padding: 2px 8px;
}

.expContainer {
  background-color: white;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 25px 40px 10px;
}

.expFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 50px;
          column-gap: 50px;
}

.expFlex .line {
  width: 1px;
  margin: 30px 0;
  background-color: #b6b6b6;
}

.expFlex .leftContainer, .expFlex .rightContainer {
  width: 50%;
  border-radius: 5px;
  padding: 20px 0;
}

.expFlex .leftContainer p, .expFlex .rightContainer p {
  margin-top: 10px;
}

.otherExpFlex, .currentExpFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.otherExpFlex img, .currentExpFlex img {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.respTitle {
  margin-bottom: 10px;
}

.respDescription {
  padding: 0 40px;
}

.positionTitle {
  padding-bottom: 5px;
}

#pixelWalrusLogo {
  width: 150px;
}

#toyotaLogo {
  width: 75px;
}

#uwLogo {
  width: 70px;
  margin-top: 10px;
  -webkit-clip-path: circle();
          clip-path: circle();
}

#canadaLogo {
  width: 70px;
  -webkit-clip-path: circle();
          clip-path: circle();
}

#teslaLogo {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  height: 50px;
}

.currentExpFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px auto;
  padding-bottom: 15px;
  max-width: 400px;
}

.otherExpFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 2em;
          column-gap: 2em;
  margin-top: 5px;
}

.expLogoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: #e7e7e7 1px solid;
  border-radius: 50%;
  background-color: white;
  height: 85px;
  width: 85px;
  margin-top: 5px;
  overflow: hidden;
}

.portfolioSection {
  text-align: center;
}

.allProjectsContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: (auto)[2];
      grid-template-rows: repeat(2, auto);
  justify-items: center;
}

.projectFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  min-width: 425px;
  height: 530px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #c9dbf5;
  text-align: left;
}

.projectImg {
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  position: relative;
}

.projectImg img {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

#projectImgBkgd {
  width: 100%;
  -webkit-filter: blur(15px);
          filter: blur(15px);
}

.projectTitle, .projectText {
  padding: 0px 20px;
}

.projectDisclaimer {
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0 25px 25px;
}

.projectSkills {
  -ms-flex-item-align: start;
      align-self: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  padding: 0 20px 10px;
}

.projectSkillTile {
  background-color: #6c757d;
  border-radius: 5px;
  padding: 0px 8px;
  margin-bottom: 15px;
  color: white;
}

.projectTitle {
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  font-size: x-large;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.projectTitle a {
  color: black;
  text-decoration: none;
  margin: 10px 0;
}

.projectTitle #github {
  width: 25px;
  padding-bottom: 5px;
}

.projectText {
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
}

.projectText div {
  padding-bottom: 5px;
}

.projectDiv {
  -ms-flex-item-align: start;
      align-self: flex-start;
  text-align: left;
}

#highlightBadge {
  background-color: #159bdb !important;
  padding: 6px;
}

.contactSection {
  padding-bottom: 200px;
}

.contactBox {
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  padding: 25px;
}

.contactGrid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50px auto;
      grid-template-columns: 50px auto;
  -ms-grid-rows: (auto)[6];
      grid-template-rows: repeat(6, auto);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  justify-items: start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.contactGrid .contactInfo, .contactGrid #linkedin, .contactGrid #github, .contactGrid #email {
  margin-bottom: 20px;
}

.contactGrid .contactInfo a, .contactGrid #linkedin a, .contactGrid #github a, .contactGrid #email a {
  color: black;
}

.contactGrid img {
  width: 40px;
  -webkit-filter: invert(46%) sepia(97%) saturate(412%) hue-rotate(161deg) brightness(90%) contrast(86%);
          filter: invert(46%) sepia(97%) saturate(412%) hue-rotate(161deg) brightness(90%) contrast(86%);
}

.contactGrid #linkedin {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
}

.contactGrid #github {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3/5;
}

.contactGrid #email {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -ms-grid-row: 5;
  -ms-grid-row-span: 2;
  grid-row: 5/7;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

h1 {
  font-family: "Merriweather", serif;
  font-size: 62px;
  margin: 0px;
}

h2 {
  font-size: 26px;
  margin-top: 8px;
}

h3, .largeFont {
  font-size: 20px;
}

.smallFont {
  font-size: 14px;
}

.underline {
  border-bottom: #b6b6b6 1px solid;
}

.noTopMargin {
  margin-top: 0;
}

.noBottomMargin {
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.xBold {
  font-weight: 1000;
}

.whiteBackgroud {
  background-color: white;
}

.mobileText {
  display: none;
}

@media (max-width: 1599px) {
  .respDescription {
    padding: 0 25px;
  }
}

@media (max-width: 1250px) {
  main {
    margin-left: 250px;
  }
  section {
    width: 90%;
    min-width: 650px;
    padding: 40px 10px;
    margin: 0 auto;
  }
  .sectionContainer {
    min-width: 600px;
    max-width: 600px;
    margin: 0 auto;
  }
  .webNav {
    width: 250px;
  }
  .introFlex {
    padding: 75px 0;
  }
  .introFlex h1 {
    padding: 10px 0;
  }
  .aboutText {
    padding: 0 10px;
  }
  .skillsGrid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: (auto)[8];
        grid-template-rows: repeat(8, auto);
    padding: 20px;
  }
  .skillsGrid .leftContainer {
    margin-bottom: 0px;
  }
  .skillsGrid .rightContainer {
    margin-bottom: 50px;
  }
  .skillsGrid .expGridTitle {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .skillsGrid .devSkillsLineTitle-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
  }
  .skillsGrid .devSkillsLineTitle-2 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4/5;
  }
  .expGridTitle {
    margin-top: 15px;
  }
  .leftContainer, .rightContainer {
    -ms-grid-column-align: center;
        justify-self: center;
  }
  .devSkillsLine-1 img {
    height: 100px;
    margin-bottom: 0px;
  }
  .devSkillsLine-2 img {
    max-width: 65px;
    max-height: 55px;
  }
  .bottomContainer {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .otherSkillsFlex {
    width: auto;
  }
  .expContainer {
    background-color: white;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px 15px 10px;
  }
  .line {
    display: none;
  }
  .expFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .expFlex .leftContainer, .expFlex .rightContainer {
    width: 100%;
  }
  .expFlex .rightContainer {
    margin-bottom: 0;
  }
  .expFlex .leftContainer {
    padding-bottom: 0;
  }
  .expFlex p {
    margin: 0 30px;
  }
  .currentExpFlex {
    margin: 0 auto 20px;
    max-width: 400px;
  }
  .otherExpFlex {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 10px;
    padding-top: 0;
    margin-top: 0;
  }
  .expTitle {
    margin: 25px 30px;
  }
  .allProjectsContainer {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: (auto)[2];
        grid-template-rows: repeat(2, auto);
    row-gap: 20px;
  }
  .projectFlex {
    min-width: 350px;
    width: 100%;
  }
  .projectTitle {
    padding-bottom: 0;
  }
  .projectText {
    padding: 10px 10px 50px;
  }
  h2 {
    font-size: 22px;
  }
  h3, .largeFont {
    font-size: 18px;
  }
  .smallFont {
    font-size: 14px;
  }
}

@media (max-width: 967px) {
  main {
    margin: 0;
  }
  .webNav {
    display: none;
  }
  .navbar {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    position: fixed;
    z-index: 999;
    top: 20px;
    right: 30px;
    padding: 0;
  }
  .container-fluid {
    margin: 0;
    padding: 0;
  }
  .bg-light {
    background-color: white !important;
    border-radius: 0.25rem;
  }
  #offcanvasNavbar {
    width: 200px;
  }
  .navbar-toggler {
    margin: 0;
  }
}

@media (max-width: 670px) {
  .mobileText {
    display: block;
  }
  .webText {
    display: none;
  }
  main {
    font-size: 16px;
  }
  section {
    min-width: 375px;
    padding: 40px 10px;
    margin: 0 auto;
  }
  .sectionContainer {
    min-width: 350px;
  }
  #offcanvasNavbar {
    width: 150px;
  }
  #profilePic {
    width: 275px;
    padding: 50px 0;
  }
  .skillsGrid {
    padding: 20px 10px 10px;
  }
  .skillsGrid .leftContainer {
    margin-bottom: 20px;
  }
  .expFlex p {
    margin: 0;
  }
  .expGridTitle {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
  }
  .devSkillsLine-1, .devSkillsLine-2 {
    font-size: 14px;
  }
  .devSkillsLine-1 {
    padding: 0;
  }
  .devSkillsLine-1 img {
    height: 65px;
    margin-bottom: 0px;
  }
  .devSkillsLine-2 img {
    max-width: 50px;
    max-height: 45px;
  }
  .bottomContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 10px 0;
  }
  .otherSkillsFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    width: 300px;
    padding: 10px;
  }
  .currentExpFlex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    row-gap: 20px;
  }
  .respDescription {
    padding: 0;
  }
  .otherExpFlex {
    -webkit-column-gap: 1em;
            column-gap: 1em;
  }
  .expTitle {
    margin: 0 15px 25px;
  }
  .projectText {
    padding: 10px 25px 50px;
  }
  .projectSkillTile {
    padding: 2px 4px;
  }
  h1 {
    font-family: "Merriweather", serif;
    font-size: 36px;
    margin: 0px;
  }
  h2 {
    font-size: 22px;
  }
  h3, .largeFont {
    font-size: 18px;
  }
  .smallFont {
    font-size: 14px;
  }
}
