
$heading-font: 'Merriweather', serif;
$box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: 'Be Vietnam Pro', sans-serif;
}
  
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #7085b1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

main{
  margin-left: 300px;
  
  #topBtn {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    width: 50px;
    height: 50px;
    position: fixed; 
    bottom: 20px; 
    right: 30px; 
    z-index: 99; 
    border: none; 
    outline: none; 
    background-color: #159bdb; 
    cursor: pointer;
    border-radius: 50%;
    img{
      margin: auto;
      width: 70%;
      filter: invert(1);
    }
  }
  
  #topBtn:hover {
    background-color: #d7e3f7;
    img{
      filter: invert(52%) sepia(46%) saturate(4655%) hue-rotate(170deg) brightness(97%) contrast(84%);
    }
  }
}

section {
  width: 100%;
  padding: 50px 25px;
  background-color: #f5f8fd;
}

h2.sectionTitle{
  font-size: 26px;  
  text-align: left;
  margin-bottom: 30px;
  text-decoration: underline;
  text-decoration-color: #159bdb;
}

.sectionContainer{
  min-width: 900px;
  max-width: calc(1920px - 600px);
  margin: 0 auto ;
}

.webNav{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  padding: 25px;
  background-color: black;
  color: white;
  
  .navPicContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin: 5px auto 10px;
    border-radius: 50%;
    border:8px solid #2c2f3f;
    background-color: #d1d2cd;

    #navPic{
      width: 130px;
      margin-top: -5px;
      padding-left: 3px;
    }
  }

  .contactFlex{
    display: flex;
    justify-content: center;
    column-gap: 8px;
    margin: 5px 0;
    .navSvgContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #2c2f3f;
    }
    
    #linkedin, #email, #github{
      width: 80%;
      border-radius: 50%;
      filter: invert(1);
    }
  
    #github{
      width: 60%;
      padding-bottom: 2px;
    }
  
    #email{
      width: 85%;
      padding: 0 0 1px 0 ;
    }
  }

  .navSvgContainer:hover{
    background-color: #1483dd;
  }

  .navLinkFlex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 25px;
  }

  h3, .largeFont{
    padding: 10px 0;
  } 

  .navLink{
    color: #a8a9b4;
    img{
      // Invert svg with black lines
      filter: invert(71%) sepia(6%) saturate(367%) hue-rotate(197deg) brightness(95%) contrast(91%);
    }
  }

  .navLink:hover{
    color: white;
  }
  
  .navLinkAtScroll {
    img{
      filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
    }
    color: white;
  }
}

.navLink{
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  justify-items: center;
  column-gap: 10px;
  padding: 10px 0;
  img{
    height: 18px;
  }
  span{
    justify-self: start;
  }
}

.navLink:hover{
  img{
    filter: invert(43%) sepia(94%) saturate(505%) hue-rotate(156deg) brightness(99%) contrast(95%);
  }
  cursor: pointer
}

//Mobile React Bootstrap nav
.navbar{
  display: none;
}

#offcanvasNavbar{
  .navLink, .navLink:hover{
    color: black;
  }
}

.aboutContainer{
  text-align: left;
  padding-top: 50px;
}

.aboutText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#profilePic {
  width: calc(1080px/3);
  border-radius: 50%;
  margin: 20px 0 0;
  order: 0;
}

.skillsSection{
  h2{
    margin: 0
  }
  .resumeTitle{
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin: 8px 0 30px;
  }
  
  .resumeDownload{
    font-size: 14px;
    min-width: 23px;
  }
  
}


.skillsGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);
  column-gap: 4em;
  border-radius: 5px;
  margin: 0 0 50px;
  padding: 20px 40px 10px; 
  background-color: white;
  box-shadow: $box-shadow;

  .expGridTitle{
    grid-column: 1/3;
  }

  .devSkillsLineTitle-1, .devSkillsLineTitle-2{
    padding-top: 20px;
    margin-bottom: 10px;
  }

  .devSkillsLineTitle-1{
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .devSkillsLineTitle-2{
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .leftContainer, .rightContainer{
    width: 90%;
    justify-self: center;
    margin-bottom: 20px;
    p{
      margin-top: 10px;
    }
  }
}

.devSkillsLine-1, .devSkillsLine-2{
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  column-gap: 20px;
  img{
    padding-top: 10px;
  }
}
    
.devSkillsLine-1{
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  padding-bottom: 10px;
  img{
    height: 90px;
    margin-bottom: 0px;
  }
}
  
.devSkillsLine-2{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, auto);
  img{
    max-width: 50px;
    max-height: 45px;
  }
}  

.bottomContainer{
  grid-column: 1/3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #e7effd;
  border-radius: 10px;
  margin-top: 10px;
}

.otherSkillsFlex{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  padding: 20px 0;
  row-gap: 15px;
}

.otherSkillTile{
  display: flex;
  align-items: center;
  border: rgb(221, 221, 221) 1px solid;
  background-color: white;
  border-radius: 5px;
  padding: 2px 8px;
}

.expContainer{
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 5px;
  padding: 25px 40px 10px; 
}

.expFlex{
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
  
  .line{
    width: 1px;
    margin: 30px 0;
    background-color: rgb(182, 182, 182);
  }

  .leftContainer, .rightContainer{
    width: 50%;
    border-radius: 5px;
    padding: 20px 0;

    p{
      margin-top: 10px;
    }
  }
}
  
.otherExpFlex, .currentExpFlex{
  display: flex;
  align-items: center;

  img{
    align-self: center;
  }
}

.respTitle{
  margin-bottom: 10px;
}

.respDescription{
  padding: 0 40px;
}

.positionTitle{
  padding-bottom: 5px;
}

#pixelWalrusLogo{
  width: 150px;
}

#toyotaLogo{
  width: 75px;
}
#uwLogo{
  width: 70px;
  margin-top: 10px;
  clip-path: circle();
}
#canadaLogo{
  width: 70px;
  clip-path: circle();
}
#teslaLogo{
  align-self: center;
  height: 50px;
}

.currentExpFlex{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;
  padding-bottom: 15px;
  max-width: 400px;
}

.otherExpFlex{
  display: flex;
  justify-content: center;
  column-gap: 2em;
  margin-top: 5px;
}

.expLogoContainer{
  display: flex;
  justify-content: center;
  border: rgb(231, 231, 231) 1px solid;
  border-radius: 50%;
  background-color: white;
  height: 85px;
  width: 85px;
  margin-top: 5px;
  overflow: hidden;
}

.portfolioSection{
  text-align: center;
}

.allProjectsContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  justify-items: center;
}

.projectFlex{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-width: 425px;
  height: 530px;
  box-shadow: $box-shadow;
  border-radius: 5px;
  background-color: #c9dbf5;
  text-align: left;
}

.projectImg{
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  align-self: center;
  // margin: auto;
  position: relative;

  img {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
}

#projectImgBkgd{
  width: 100%;
  filter: blur(15px);
}

.projectTitle, .projectText{
  padding: 0px 20px ;
}

.projectDisclaimer{
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  // vertical-align: bottom;
  flex-grow: 1;
  // bottom: 0;
  padding: 0 25px 25px;
}

.projectSkills{
  align-self: start;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  padding: 0 20px 10px;
  // align-items: center;
}

.projectSkillTile{
  background-color: #6c757d;
  border-radius: 5px;
  padding: 0px 8px;
  margin-bottom: 15px;
  color: white;
}

.projectTitle{
  align-self: flex-start;
  display: flex;
  align-items: baseline;
  font-size: x-large;
  column-gap: 10px;
  
  a{
    color: black;
    
    text-decoration: none;
    margin: 10px 0;
  }

  #github{
    width: 25px;
    padding-bottom: 5px;
  }
} 

.projectText{
  align-self: start;
  div{
    padding-bottom: 5px;
  }
}

.projectDiv{
  align-self: flex-start;
  text-align: left;
}


#highlightBadge{
  background-color: #159bdb !important;
  padding: 6px;
}

.contactSection{
  padding-bottom: 200px;
}

.contactBox{
  border-radius: 5px;
  background-color: white;
  box-shadow: $box-shadow;
  padding: 25px;
}

.contactGrid{
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: repeat(6, auto);
  justify-content: start;
  justify-items: start;
  align-items: center;
  column-gap: 5px;

  .contactInfo, #linkedin, #github, #email{
    margin-bottom: 20px;
    
    a{
      color: black;
    }
  }

  img{
    width: 40px;
    filter: invert(46%) sepia(97%) saturate(412%) hue-rotate(161deg) brightness(90%) contrast(86%);
  }
  #linkedin{
    order: 0;
    grid-row: 1/3;
  }
  #github{
    order: 1;
    grid-row: 3/5;
  }
  #email{
    order: 2;
    grid-row: 5/7;
  }
}


// .footerSection{
//   position: fixed;
//   text-align: center;
//   width: 100%;
//   bottom: 0;
//   padding-bottom: 10px;
//   background-color:white;
// }

.alignCenter{
  text-align: center;
}

.alignLeft{
  text-align: left;
}

h1{
  font-family: $heading-font;
  font-size: 62px;
  margin: 0px;
}

h2{
  font-size: 26px;
  margin-top: 8px;
}

h3, .largeFont{
  font-size: 20px;
}

.smallFont{
  font-size: 14px;
}

.underline{
  // border-bottom: black 1px solid;
  border-bottom: rgb(182, 182, 182) 1px solid;
}

.noTopMargin{
  margin-top: 0;
}

.noBottomMargin{
  margin-bottom: 0;
}

.bold{
  font-weight: bold ;
}
.xBold{
  font-weight: 1000 ;
}
.whiteBackgroud{
  background-color: white;
}

.mobileText{
  display: none;
}

@media (max-width: 1599px) {
  .respDescription{
    padding: 0 25px;
  }
}

@media (max-width: 1250px) {
  
  main{
    margin-left: 250px;
    // font-size: 16px;
  }

  section{
    width: 90%;
    min-width: 650px;
    // max-width: calc(1920px - 600px);
    padding: 40px 10px;
    margin: 0 auto ;
  }
  
  // h2.sectionTitle{
  //   font-size: 26px; 
  // }

  .sectionContainer{
    min-width: 600px;
    max-width: 600px;
    margin: 0 auto ;
  }

  .webNav{
    width: 250px;
  }

  .introFlex{
    padding: 75px 0;
    h1{
      padding: 10px 0;
    }
  }

  .aboutText{
    padding: 0 10px;
  }

  .skillsGrid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
    padding: 20px; 

    .leftContainer{
      margin-bottom: 0px;
    }

    .rightContainer{
      margin-bottom: 50px;
    }

    .expGridTitle{
      grid-column: 1/2;
    }

    .devSkillsLineTitle-1{
      grid-column: 1/2;
      grid-row: 2/3;
    }
    .devSkillsLineTitle-2{
      grid-column: 1/2;
      grid-row: 4/5;
    }
  }

  .expGridTitle{
    margin-top: 15px;
  }

  .leftContainer, .rightContainer{
    justify-self: center;
  }

  .devSkillsLine-1{
    img{
      height: 100px;
      margin-bottom: 0px;
    }
  }
    
  .devSkillsLine-2{
    img{
      max-width: 65px;
      max-height: 55px;
    }
  }  

  .bottomContainer{
    grid-column: 1/2;
    display: flex;
    justify-content: space-evenly;
  }

  .otherSkillsFlex{
    width: auto;
  }

  .expContainer{
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: 5px;
    padding: 20px 15px 10px; 
  }

  .line{
    display: none;
  }

  .expFlex{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // column-gap: 50px;
    .leftContainer, .rightContainer{
      width: 100%;
    }

    .rightContainer{
      margin-bottom: 0;
    }

    .leftContainer{
      padding-bottom: 0;
    }

    p {
      margin: 0 30px;
    }
  }

  .currentExpFlex{
    margin: 0 auto 20px;
    max-width: 400px;
  }

  .otherExpFlex{
    justify-content: center;
    padding-top: 10px;
    padding-top: 0;
    margin-top: 0;
  }

  .expTitle{
    // padding: 15px 0;
    margin: 25px 30px;
  }

  .allProjectsContainer{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    row-gap: 20px;
    // justify-items: center;
  }

  .projectFlex{
    min-width: 350px;
    width: 100%;
  }

  .projectTitle{
    padding-bottom: 0;
  }

  .projectText{
    padding: 10px 10px 50px;
  }
  
  h2{
    font-size: 22px;
  }
  
  h3, .largeFont{
    font-size: 18px;
  }
  
  .smallFont{
    font-size: 14px;
  } 
  
}

@media (max-width: 967px){
  
  main{
    margin: 0;
  }

  .webNav{
    display: none;
  }

  .navbar{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    display: flex;
    margin: auto;
    position: fixed;
    z-index: 999;
    top: 20px; 
    right: 30px; 
    padding: 0;
  }
  
  //Boostrap mobile nav
  .container-fluid{
    margin: 0;
    padding: 0;
  }
  
  .bg-light{
    background-color: white !important;
    border-radius: 0.25rem;
  }
  
  #offcanvasNavbar{
    width: 200px;
  }

  .navbar-toggler{
    margin: 0;
  }

}

@media (max-width: 670px) {
  .mobileText{
    display: block;
  }

  .webText{
    display: none;
  }

  main{
    font-size: 16px;
  }

  section{
    // width: 80%;
    min-width: 375px;
    padding: 40px 10px;
    margin: 0 auto ;
  }

  .sectionContainer{
    min-width: 350px;
    // max-width: 350px;
  }

  //bootstrap mobile Nav 
  #offcanvasNavbar{
    width: 150px;
  }

  #profilePic {
    width: 275px;
    padding: 50px 0;
  }

  .skillsGrid{
    padding: 20px 10px 10px; 
    
    .leftContainer{
      margin-bottom: 20px;
    }
  }

  .expFlex{
    p {
      margin: 0;
    }
  }

  .expGridTitle{
    grid-column: 1/2;
  }

  .devSkillsLine-1, .devSkillsLine-2{
    font-size: 14px;
  }

  .devSkillsLine-1{
    padding: 0;
    img{
      height: 65px;
      margin-bottom: 0px;
    }
  }
    
  .devSkillsLine-2{
    img{
      max-width: 50px;
      max-height: 45px;
    }
  } 

  .bottomContainer{
    flex-direction: column;
    justify-content: center;
    // margin: auto;
    padding: 10px 0;
  }

  .otherSkillsFlex{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    column-gap: 20px;
    width: 300px;
    padding: 10px;
  }

  .currentExpFlex{
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    // column-gap: 30px;
  }

  .respDescription{
    padding: 0;
  }

  .otherExpFlex {
    column-gap: 1em;
  }

  .expTitle{
    // padding: 15px 0;
    margin: 0 15px 25px;
  }

  .projectText{
    padding: 10px 25px 50px;
  }

  .projectSkillTile{
    padding: 2px 4px;
  }

  h1{
    font-family: $heading-font;
    font-size: 36px;
    margin: 0px;
  }

  h2{
    font-size: 22px;
  }
  
  h3, .largeFont{
    font-size: 18px;
  }
  
  .smallFont{
    font-size: 14px;
  }
}
